import { Box, HStack, Input, InputGroup, InputLeftElement, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react';

import CameraSelector from '../components/In&Out/CameraSelector';
import DisplayInfo from '../components/Information/DisplayInfo';
import GetDuration from '../components/In&Out/GetDuration';
import Header from '../components/Header/Header';
import InTime from '../components/In&Out/InTime';
import Layout from './Layout';
import { LinkIcon } from "@chakra-ui/icons"
import OutTime from '../components/In&Out/OutTime';
import SendIt from '../components/Sender/SendIt';
import UserContext from '../auth/UserContext';
import VideoPlayer from '../components/VideoPlayer/ReactVideoPlayer';
import { useContext } from "react";
import {useNavigate} from "react-router-dom"
import { useStore } from 'react-context-hook';

// import HlsPlayer from './components/VideoPlayer/ReactHlsPlayer';










// import Zoom from '../components/Zoom/Zoom';



const Stream = () => {
	const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [endpoint, setEndpoint] = useStore('endpoint', 'https://isproxy.ozapi.net/venueX/replay/bookmarks');


  useEffect(() => {
    if (!currentUser?.email) 
      navigate('/')
  }
  ,[currentUser?.email, navigate])

	return (
    <>
      <Layout>
      {currentUser?.email && 
   
        <Box bg="gray.800">
          {/* <HlsPlayer /> */}
          <Header />
          <VStack spacing={6} mb={5}>
            <VideoPlayer />
            <HStack spacing={4} bg="gray.600" mb={10}>
              <DisplayInfo />
              <SendIt />
            </HStack>
            <HStack spacing={5} bg="gray.300">
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<LinkIcon color="gray.800" />}
                  size="lg"
                />
                <Input
                  size="lg"
                  variant="filled"
                  placeholder="Video URL"
                  value={endpoint}
                  htmlSize={40} 
                  width='auto'
                  onChange={(e) => setEndpoint(e.target.value)}
                />
              </InputGroup>     
            </HStack>
          </VStack>

          {/* controls */}
          <InTime />
          <OutTime />
          <GetDuration />
          <CameraSelector />

          {/* <Zoom /> */}
          {/* <h1>Epoch Clock: {epochClock()}</h1> */}
        </Box>
      }
      </Layout>
    </>
  );
};

export default Stream;