///display the current information of store

import { useStore } from 'react-context-hook';
import React from 'react';
import './DisplayInfo.css';
// import { useEffect } from 'react';

const DisplayInfo = () => {
    const [inTime] = useStore('inTimeKey', 0);
    const [outTime] = useStore('outTimeKey', 0);
    const [duration] = useStore('durationKey', 0);
    const [camera] = useStore('cameraKey', '');
    const [endpoint] = useStore('endpoint', 'https://isproxy.ozapi.net/venueX/replay/bookmarks');


    // useEffect(() => {
    //     console.log('this is the in time: ', inTime);
    //     console.log('this is the out time: ', outTime);
    //     console.log('this is the duration: ', duration);
    // }, [inTime, outTime, duration]);

    //convert inTime and outTime from epoch to real time
    const inTimeReal = new Date(inTime).toLocaleTimeString();
    const outTimeReal = new Date(outTime).toLocaleTimeString();

    return (
        <div className="infoDisplay">
            <h1>In ⏱: {inTimeReal} ⚽️</h1>
            <h1>Out ⏱: {outTimeReal} ⚽️</h1>
            <h1>Duration of clip (ms): {duration} 🎞</h1>
            <h1>Camera selected: {camera} 🎥</h1>
            <h1>Endpoint 🔗: {endpoint}</h1>
        </div>
    );
};

export default DisplayInfo;
