import React from 'react';

import Layout from './Layout';
import RegisterForm from '../components/RegisterForm';

const Register = () => {
	return (
		<>
			<Layout>
                <RegisterForm />
			</Layout>
		</>
	);
};

export default Register;