// create a component to send all the information to the server with a click of a keyboard button "s"
import React from 'react';
import { useStore } from 'react-context-hook';

import axios from 'axios';
import './SendIt.css';

export default function SendIt() {
    const [inTime] = useStore('inTimeKey', 0);
    const [duration] = useStore('durationKey', 0);
    const [camera] = useStore('cameraKey', '');
    const [endpoint] = useStore('endpoint', 'https://isproxy.ozapi.net/venueX/replay/bookmarks');

    console.log('Current endpoint: ', endpoint);

    const onSubmit = async (e) => {
        e.preventDefault();
        const post = {
            eventtime: inTime,
            source: camera,
            duration: duration,
        };
        try {
            const response = await axios.post(
                endpoint,
                post
            );
            console.log(response.data);
        } catch (e) {
            alert(e);
        }
    };

    return (
        <div className="form-div">
            <form className="form-submit" onSubmit={onSubmit}>
              <button className="submit-button" type="submit">
                    Submit
                </button>
                </form>
        </div>
    );
}
