import React from 'react';
import SidebarWithHeader from "../components/SidebarWithHeader"

const Layout = ({ children }) => {    
	return (
            <SidebarWithHeader>
            { children } 
            </SidebarWithHeader>
	);
};

export default Layout;