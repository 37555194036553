//Create a header that has the logo on the left, png for logo is in current folder.

import React from 'react';
import './Header.css';

const Header = () => {
    return (
        <div className="header">
            <img className="logo" src={require('./logo.png')} alt="logo" />
            <h1 className="title-header"> Replay system </h1>
        </div>
    );
};

export default Header;
