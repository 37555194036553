import React, { useEffect } from 'react';
import { useStore } from 'react-context-hook';

// create a custom component for the in time
const InTime = () => {
    // create a state variable for the in time
    // eslint-disable-next-line no-unused-vars
    const [inTime, setInTime] = useStore('inTimeKey', 0);

    useEffect(() => {
        document.addEventListener('keydown', inTimeHandler);
        return () => {
            document.removeEventListener('keydown', inTimeHandler);
        };
    });

    const inTimeHandler = (event) => {
        if (event.key === 'i' || event.key === 'I') {
            const video = document.querySelector('video');
            const canvas = document.querySelector('canvas');
            const ctx = canvas.getContext('2d', { willReadFrequently: true });
            ctx.drawImage(
                video,
                0,
                video.videoHeight - 4,
                video.videoWidth,
                4,
                0,
                0,
                canvas.width,
                4
            );
            // create a list of bites where each bite is a 24-byte pixel where white is 1 and black is 0
            const bites = [];
            let x = 0;

            const imageData = ctx.getImageData(0, 0, 1920, 1);
            for (let i = 48; i < imageData.data.length; i += 96) {
                const r = imageData.data[i];
                const g = imageData.data[i + 1];
                const b = imageData.data[i + 2];
                const a = imageData.data[i + 3];
                // if the pixel is mostly white, add a 1 to the list of bites
                if (r > 200 && g > 200 && b > 200 && a > 200) {
                    bites.push(1);
                    x += 2 ** (80 - bites.length);
                } else {
                    // otherwise add a 0
                    bites.push(0);
                }
            }

            // console.log(bites);
            console.log('this is the in time in InTime.js: ', x);
            //set the in time
            setInTime(x);
            // console.log(Date.now());
        }
    };
    // const inContext = createContext(inTime);

    //return the in time and the context
    return (
        <div>
            <canvas width="1920" height="1" />
            {/* <p>{inTime}</p> */}
        </div>
    );
};

export default InTime;
