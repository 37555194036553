import {useContext} from "react";
import UserContext from "../auth/UserContext"
import { Card, Box, Heading, Center, VStack, Text} from "@chakra-ui/react"
import { Grid, GridItem } from '@chakra-ui/react'

function UserProfile() {

const { currentUser } = useContext(UserContext);
var myDate = new Date(parseInt(currentUser.lastLoginAt));
const loginDate = myDate.toString()
console.log(loginDate);
  
return (
  <>
    <Center>
      <Card m={4}>
        <VStack w="600px" p={16} alignItems="flex-start">
          <Heading as="h5" id="login-form" size={"large"}>
            User Profile
          </Heading>
          <Box p={6} rounded="md" w="100%">
            {currentUser?.email ? (
              <Grid templateColumns="repeat(2, 1fr)" gap={6}>
              <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                >
                  Email
                </GridItem>
                <GridItem
                  w="100%"
                  h="10"
                  textAlign="left"
                  verticalAlign="baseline"
                >
                  <Text fontWeight="bold">{currentUser.email}</Text>
                </GridItem>

                <GridItem
                  w="100%"
                  h="20"
                  textAlign="left"
                  verticalAlign="baseline"
                >
                  Last login
                </GridItem>
                <GridItem
                  w="100%"
                  h="20"
                  textAlign="left"
                  verticalAlign="baseline"
                >
                { }
                  <Text fontWeight="bold">{loginDate}</Text>
                </GridItem>
                
                
              </Grid>
            ) : (
              <>Please Log in.</>
            )}
          </Box>
        </VStack>
      </Card>
    </Center>
  </>
);
}

export default UserProfile;