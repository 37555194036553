import React from 'react';

import Layout from './Layout';
import { useContext } from "react";
import UserContext from '../auth/UserContext';
import { useNavigate } from "react-router-dom"
import LoginForm from '../components/LoginForm';

const Home = () => {
	const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

	return (
    <>
      <Layout>
        {!currentUser?.email ? (
          <LoginForm />
        ) : 
            navigate('/stream')
        }
      </Layout>
    </>
  );
};

export default Home;