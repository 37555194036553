import React, { ReactNode } from 'react';
import {
  IconButton,
  Avatar,
  AvatarBadge,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Button,
  Tooltip
} from '@chakra-ui/react';

import {
  FiBell,
  FiGlobe,
  FiServer,
  FiZapOff,
  FiZap,
  FiChevronDown,
} from 'react-icons/fi';
import {
    FiHome,
    FiTrendingUp,
    FiCompass,
    FiStar,
    FiSettings,
    FiMenu,
    FiDollarSign,
    FiUser
  } from 'react-icons/fi';
import { IconType } from 'react-icons';
import { ReactText } from 'react';
import { Link as RouterLink, useNavigate, redirect } from 'react-router-dom'
import { useContext } from "react";
import UserContext from "../auth/UserContext"

const LinkItems = [
    { name: 'Quick Orders', icon: FiDollarSign, to: '/quick-orders' },
  //   { name: 'Trending', icon: FiTrendingUp },
  //   { name: 'Explore', icon: FiCompass },
  //   { name: 'Favourites', icon: FiStar },
    { name: 'Profile', icon: FiUser, to: '/profile' },
  ];
  

export default function SidebarWithHeader({children}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const topNavBarLeftMargin = currentUser?.name ? { base: 0, md: 60 } : { base: 0, md: 0 };
  
  return (
    <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
    { currentUser?.name && (
      <>
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full">
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      </>)
    }
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} />

      <Box ml={topNavBarLeftMargin} p="4">
        {children}
      </Box>
    </Box>
  );
}


const SidebarContent = ({ onClose, ...rest }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue('white', 'gray.900')}
      borderRight="1px"
      borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}>
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Text fontSize="xl" fontWeight="bold">
            <Link href={""}>OZ</Link>
        </Text>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex> 
      {LinkItems.map((link) => (
        <NavItem key={link.name} icon={link.icon} to={link.to}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ icon, children, to, ...rest }) => {
  return (
    <Link as={RouterLink} to={to} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'pink.500',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};


const MobileNav = ({ onOpen, ...rest }) => {
  const { currentUser, setCurrentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const topNavBarLeftMargin = currentUser?.name ? { base: 0, md: 60 } : { base: 0, md: 0 };
  const isLight = true;

  console.log("Sidebar", currentUser);

  const handleLogOut = () => {
      localStorage.removeItem("user");
      setCurrentUser({});
      navigate("/");
  };

  return (
    <>
      <Flex
        ml={topNavBarLeftMargin}
        px={{ base: 4, md: 4 }}
        height="20"
        alignItems="center"
        bg={isLight ? "white" : "gray.900"}
        borderBottomWidth="1px"
        borderBottomColor={isLight ? "gray.200" : "gray.700"}
        justifyContent={{ base: "space-between", md: "flex-end" }}
        {...rest}
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontWeight="bold"
        >
         OZ
        </Text>

        <HStack spacing={{ base: "0", md: "6" }}>
          <Flex alignItems={"center"}>
            {currentUser?.email ? (
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: "none" }}
                >
                  <HStack>
                 
                      <Avatar size={"sm"} name={currentUser ? currentUser?.email : 'Not Authorized' }>      
                      </Avatar>
                    <VStack
                      display={{ base: "none", md: "flex" }}
                      alignItems="flex-start"
                      spacing="1px"
                      ml="2"
                    >
                      <Text fontSize="sm">{currentUser?.name}</Text>
                      <Text fontSize="xs" color="gray.600">
                        {currentUser?.email}
                      </Text>
                    </VStack>
                    <Box display={{ base: "none", md: "flex" }}>
                      <FiChevronDown />
                    </Box>
                  </HStack>
                </MenuButton>
                <MenuList
                  bg={isLight ? "white" : "gray.900"}
                  borderColor={isLight ? "gray.200" : "gray.700"}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleLogOut}>Sign out</MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <>
                <Link as={RouterLink} to="/login">
                  <Button variant="ghost" colorScheme="brand.red">
                    Sign in
                  </Button>
                </Link>
                <Link as={RouterLink} to="/register">
                  <Button variant="outline" bg="brand.red" color='white'>
                    Sign up
                  </Button>
                </Link>
              </>
            )}
          </Flex>
        </HStack>
      </Flex>
    </>
  );
};