import './ReactVideoPlayer.css';

import { Button, Input, InputGroup, InputLeftElement, Stack } from '@chakra-ui/react'

import { LinkIcon } from "@chakra-ui/icons"
import React from 'react';
import ReactPlayer from 'react-player';

//install format



class VideoPlayer extends React.Component {
    state = {
        url: 'https://isproxy.ozapi.net/venueX/playlist.m3u8',
        inputUrl: 'https://isproxy.ozapi.net/venueX/playlist.m3u8',
        playing: true,
        controls: true,
        width: '1920px',
        height: '1080px',
        muted: true,
        // loop: false,
        playbackRate: 0.5,
        // played: 0,
        // loaded: 0,
        // duration: 0,
        // seeking: false,
        // currentTime: 0,
    };
    //set arrow keys seek to 5 seconds
    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = (event) => {
        switch (event.keyCode) {
            case 37:
                this.player.seekTo(this.player.getCurrentTime() - 5);
                break;
            case 39:
                this.player.seekTo(this.player.getCurrentTime() + 5);
                break;
            default:
                break;
        }
    };

    handlePlayPause = () => {
        this.setState({ playing: !this.state.playing });
    };

    handlePlay = () => {
        this.setState({ playing: true });
    };

    // handleToggleLoop = () => {
    //     this.setState({ loop: !this.state.loop });
    // };

    // handleToggleMuted = () => {
    //     this.setState({ muted: !this.state.muted });
    // };

    // handleSetPlaybackRate = (e) => {
    //     this.setState({ playbackRate: parseFloat(e.target.value) });
    // };

    // handleProgress = (state) => {
    //     // We only want to update time slider if we are not currently seeking
    //     if (!this.state.seeking) {
    //         this.setState(state);
    //     }
    // };

    // handleDuration = (duration) => {
    //     this.setState({ duration });
    // };

    // handleSeekMouseDown = (e) => {
    //     this.setState({ seeking: true });
    // };

    // handleSeekChange = (e) => {
    //     this.setState({ played: parseFloat(e.target.value) });
    // };

    // handleSeekMouseUp = (e) => {
    //     this.setState({ seeking: false });
    //     this.player.seekTo(parseFloat(e.target.value));
    // };

    // handleProgressChange = (e) => {
    //     const played = parseFloat(e.target.value);
    //     this.setState({ played });
    //     this.player.seekTo(played);
    // };

    ref = (player) => {
        this.player = player;
    };

    //disable play pause when video is clicked

    // renderLoadButton = (url, label) => {
    //     return (
    //         <button onClick={() => this.setState({ url, playing: true })}>
    //             {label}
    //         </button>
    //     );
    // };

    render() {
        const { url, playing, controls, muted, playbackRate } = this.state;

        return (
          <div className="app">
            {/* url link input */}
            <Stack direction="row" spacing={4} bg="gray.300" mt={10} mb={10}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<LinkIcon color="gray.800" />}
                  size="lg"
                />
                <Input
                  size="lg"
                  variant="filled"
                  placeholder="Video URL"
                  value={this.state.inputUrl}
                  onChange={(e) => this.setState({ inputUrl: e.target.value })}
                />
              </InputGroup>

              <Button
                size="lg"
                leftIcon={<LinkIcon />}
                onClick={(e) => this.setState({ url: this.state.inputUrl })}
              >
                Set URL
              </Button>
            </Stack>
            {/* <section className="section"> */}
            <div className="player-wrapper">
              <script src="https://cdnjs.cloudflare.com/ajax/libs/hls.js/0.12.4/hls.js"></script>

              <ReactPlayer
                ref={this.ref}
                className="react-player"
                width="100%"
                height="100%"
                url={url}
                playing={playing}
                controls={controls}
                // loop={loop}
                playbackRate={playbackRate}
                muted={muted}
                onPlay={this.handlePlay}
                // onProgress={this.handleProgress}
                // onDuration={this.handleDuration}
              />
            </div>
            {/* </section> */}
            <section className="section">
              <table>
                <tbody>
                  <tr>
                    <td>
                      {/* style button so that it is clickable and bigger*/}

                      <button className="button" onClick={this.handlePlayPause}>
                        {playing ? "Pause" : "Play"}
                      </button>
                    </td>
                    <td></td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getCurrentTime() - 5)
                        }
                      >
                        -10
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getCurrentTime() - 2.5)
                        }
                      >
                        -5
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getCurrentTime() - 0.5)
                        }
                      >
                        -1
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(
                            this.player.getCurrentTime() - 0.25
                          )
                        }
                      >
                        👶
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getCurrentTime() + 0.5)
                        }
                      >
                        +1
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getCurrentTime() + 2.5)
                        }
                      >
                        +5
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getCurrentTime() + 5)
                        }
                      >
                        +10
                      </button>
                    </td>
                    <td>
                      <button
                        className="button"
                        onClick={() =>
                          this.player.seekTo(this.player.getDuration())
                        }
                      >
                        Live
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
            {/* create button to seek -5 -1 1 5 seconds in the video */}
          </div>
        );
    }
}

export default VideoPlayer;
