import { useFormik } from "formik";
import * as Yup from 'yup';
import {Input, Button, Card, VStack, Box, Heading, Center, Text, HStack, Link} from "@chakra-ui/react"
import {
    FormControl,
    FormLabel,
    FormErrorMessage
  } from '@chakra-ui/react'
  import { useAlertContext} from "../context/alertContext";
  import React from "react";
  import { useNavigate } from "react-router-dom";
  import signIn from "../auth/signin";

function LoginForm() {
  const navigate = useNavigate();
  const { onOpen } = useAlertContext();

  const formik = useFormik({
    initialValues: { email: "", password: ""},
    onSubmit: async (values) => {

      const googleApiResponse = await signIn(values.email, values.password);

        console.log('postLogin response', googleApiResponse);
        if (googleApiResponse.error) {
          onOpen('error', googleApiResponse.error.message);
        }
        else {
          localStorage.setItem("user", JSON.stringify(googleApiResponse.result.user));
          
          onOpen('success', "You've been successfully logged in!");
          formik.resetForm();
          // then let's log in new user
          setTimeout(() => {
            navigate("/stream");
          }, 500);
        }  
      },
    validationSchema: Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(8, 'Must be at least 8 characters').required('Required'),
    }),
  });

  const isEmailInvalid = formik.touched.email && formik.errors.email;
  const isPasswordInvalid = formik.touched.password && formik.errors.password;
 
  return (
    <Center>
     <Card m={4}>
      <VStack w="600px" p={16} alignItems="center">
          <Heading as="h5" ali size="lg" id="login-form">OZ Login Form</Heading>
          <Text>Please log in</Text>

        <Box p={6} rounded="md" w="100%">
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
              <FormControl isInvalid={isEmailInvalid}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps("email")}
                />
                { isEmailInvalid && (<FormErrorMessage>{formik.errors.email}</FormErrorMessage>)}
              </FormControl>

              <FormControl isInvalid={isPasswordInvalid}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  {...formik.getFieldProps("password")}
                />
                { isPasswordInvalid && ( <FormErrorMessage>{formik.errors.password}</FormErrorMessage> )}
              </FormControl>

              <Button type="submit" bg="brand.red" color="white" width="full">
                Login
                {/* { isLoading ? (<>Loading...</>) : (<>Login</>) } */}
              </Button>
                <HStack>
                    <Text><Link href={"/register"}>signup</Link></Text>
                </HStack>
            </VStack>
          </form>
        </Box>
      </VStack>
      </Card>
    </Center>
  );
};
export default LoginForm;