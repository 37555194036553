//create an option to send store a camera
//camera is selected with keyboard
// 1 is "camera1"
// 2 is "camera2"
// 3 is "camera3"
// 4 is "camera4"
// 5 is "camera5"
// 6 is "camera6"

import React, { useEffect } from 'react';
import { useStore } from 'react-context-hook';

const CameraSelector = () => {
    // eslint-disable-next-line no-unused-vars
    const [camera, setCamera] = useStore('cameraKey', '');

    useEffect(() => {
        document.addEventListener('keydown', cameraHandler);
        return () => {
            document.removeEventListener('keydown', cameraHandler);
        };
    });

    const cameraHandler = (event) => {
        if (event.key === '1') {
            setCamera('camera1');
        }
        if (event.key === '2') {
            setCamera('camera2');
        }
        if (event.key === '3') {
            setCamera('camera3');
        }
        if (event.key === '4') {
            setCamera('camera4');
        }
        if (event.key === '5') {
            setCamera('camera5');
        }
        if (event.key === '6') {
            setCamera('camera6');
        }
    };

    return <div></div>;
};

export default CameraSelector;
