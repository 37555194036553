import { useFormik } from "formik";
import * as Yup from 'yup';
import { Input, Button, Card, VStack, Box, Heading, Center } from "@chakra-ui/react"
import {
    FormControl,
    FormLabel,
    FormErrorMessage
  } from '@chakra-ui/react'
  import { useAlertContext} from "../context/alertContext";
  import { useNavigate } from 'react-router-dom'
  import signUp from "../auth/signup";


function RegisterForm() {
    const { onOpen } = useAlertContext();
    const navigate = useNavigate();
    const isLoading = false;

  const formik = useFormik({
    initialValues: {name: "", email: "", password: "", confirm_password: ""},
    onSubmit: async (values) => {
        const googleApiResponse = await signUp(values.email, values.password);

        console.log('postRegister response', googleApiResponse);
        if (googleApiResponse.error) {
          onOpen('error', googleApiResponse.error.message);
        }
        else {
          onOpen('info', "You've been successfully registered!");
          formik.resetForm();
          // then let's log in new user
          navigate("/");
        }  
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().min(3, 'Must be at least 3 characters').required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().min(8, 'Must be at least 8 characters').required('Required'),
      confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
      // termsAndConditions: Yup.bool().oneOf([true], 'You need to accept the Terms and Conditions').required('Required'), 
    }),
  });

  const isNameInvalid = formik.touched.name && formik.errors.name;
  const isEmailInvalid = formik.touched.email && formik.errors.email;
  const isPasswordInvalid = formik.touched.password && formik.errors.password;
  const isConfirmPasswordInvalid = formik.touched.confirm_password && formik.errors.confirm_password;
  // const isTermsAndConditionsInvalid = formik.errors.termsAndConditions;

  return (
    <Center>
     <Card m={4}>
      <VStack w="600px" p={16} alignItems="center">
        <Heading as="h5" size="lg" id="register-form">
          Register
        </Heading>
        <Box p={6} rounded="md" w="100%">
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4}>
            <FormControl isInvalid={isNameInvalid}>
                <FormLabel htmlFor="name">Full Name</FormLabel>
                <Input
                  id="name"
                  name="name"
                  {...formik.getFieldProps("name")}
                />
                { isNameInvalid && (<FormErrorMessage>{formik.errors.name}</FormErrorMessage>)}
              </FormControl>

              <FormControl isInvalid={isEmailInvalid}>
                <FormLabel htmlFor="email">Email Address</FormLabel>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  {...formik.getFieldProps("email")}
                />
                { isEmailInvalid && (<FormErrorMessage>{formik.errors.email}</FormErrorMessage>)}
              </FormControl>

              <FormControl isInvalid={isPasswordInvalid}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  id="password"
                  name="password"
                  type="password"
                  {...formik.getFieldProps("password")}
                />
                { isPasswordInvalid && ( <FormErrorMessage>{formik.errors.password}</FormErrorMessage> )}
              </FormControl>

              <FormControl isInvalid={isConfirmPasswordInvalid}>
                <FormLabel htmlFor="confirm_password">Confirm Password</FormLabel>
                <Input
                  id="confirm_password"
                  name="confirm_password"
                  type="password"
                  {...formik.getFieldProps("confirm_password")}
                />
                { isConfirmPasswordInvalid && ( <FormErrorMessage>{formik.errors.confirm_password}</FormErrorMessage> )}
              </FormControl>

              {/* <FormControl isInvalid={isTermsAndConditionsInvalid}>
                <Checkbox id="termsAndConditions"
                  name="termsAndConditions"
                  {...formik.getFieldProps("termsAndConditions")} size='md' colorScheme='teal' verticalAlign={"top"}>By signing up you agree to our <br /> <Link as={ReactLink} color='teal.500' to="/terms">Terms & Conditions </Link>and <Link as={ReactLink} color='teal.500' to="/privacy">Privacy Policy</Link></Checkbox>
               
                { isTermsAndConditionsInvalid && ( <FormErrorMessage>{formik.errors.termsAndConditions}</FormErrorMessage> )}
              </FormControl> */}

              <Button type="submit" bg="brand.red" color="white" width="full">
                { isLoading ? (<>Loading...</>) : (<>Register</>) }
              </Button>
            </VStack>
          </form>
        </Box>
      </VStack>
      </Card>
    </Center>
  );
};

export default RegisterForm;