// use the store to retrieve the data from the InTime.js

import { useStore } from 'react-context-hook';
import React from 'react';
import { useEffect } from 'react';

//get the values from the store in InTime.js
const GetDuration = () => {
    //make it so that the in time and out time are retrieved from the store only once

    const [inTime] = useStore('inTimeKey', 0);
    const [outTime] = useStore('outTimeKey', 0);
    const [duration, setDuration] = useStore('durationKey', 0);

    useEffect(() => {
        // const [inTime] = useStore('inTimeKey', 0);
        // const [outTime] = useStore('outTimeKey', 0);
        // const [duration, setDuration] = useStore('durationKey', 0);
        if (outTime > inTime) {
            setDuration(outTime - inTime);
        }
    }, [inTime, outTime, setDuration, duration]);

    return <div></div>;
};

export default GetDuration;
