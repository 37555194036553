import React from 'react';

import Layout from './Layout';
import UserProfile from '../components/UserProfile';

const Profile = () => {
	return (
		<>
			<Layout>
				<UserProfile />
			</Layout>
		</>
	);
};

export default Profile;