//import react and use state and use effect
import React from 'react';
import './App.css';

import { withStore } from 'react-context-hook';

// // import HlsPlayer from './components/VideoPlayer/ReactHlsPlayer';
// import Header from './components/Header/Header';

// import VideoPlayer from './components/VideoPlayer/ReactVideoPlayer';

// import InTime from './components/In&Out/InTime';
// import OutTime from './components/In&Out/OutTime';
// import GetDuration from './components/In&Out/GetDuration';
// import CameraSelector from './components/In&Out/CameraSelector';
// import DisplayInfo from './components/Information/DisplayInfo';
// import SendIt from './components/Sender/SendIt';
// // import Zoom from './components/Zoom/Zoom';

// routes & pages
import { Routes, Route } from 'react-router-dom'
// Pages
import Home from "./pages/Home"
import Login from "./pages/Login"
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Stream from "./pages/Stream";

const initialState = {
    inTime: 0,
    outTime: 0,
    duration: 0,
    camera: '',
    endpoint: 'https://isproxy.ozapi.net/venueX/replay/bookmarks',
};

const storeConfig = {
    listener: (state, key, prevValue, nextValue) => {
        console.log(`the key "${key}" changed in the store`);
        console.log('the old value is', prevValue);
        console.log('the current value is', nextValue);
        console.log('the state is', state);
    },
    logging: true, //process.env.NODE_ENV !== 'production'
};

//create epoch clock to display in the top right corner of the screen
// const epochClock = () => {i

function App() {
    return (
      <div className="App">
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/stream" exact element={<Stream />} />
        </Routes>
      </div>
    );
}

export default withStore(App, initialState, storeConfig);
