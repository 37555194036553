// Import the functions you need from the SDKs you need
import { initializeApp, getApps } from "firebase/app";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA87c0VhNz2HtVROLtfQ3YASnWC7HMFpos",
    authDomain: "oz-replay-ui-1c9ca.firebaseapp.com",
    projectId: "oz-replay-ui-1c9ca",
    storageBucket: "oz-replay-ui-1c9ca.appspot.com",
    messagingSenderId: "624330975700",
    appId: "1:624330975700:web:0b07b1f9d3dfe03a508e41",
    measurementId: "G-7Q4001R210"
};

// Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

export default firebase_app;